import React, { useState } from 'react'
import getStripe from '../../utils/stripe'

const formatPrice = (amount, currency) => {
  let price = (amount / 100).toFixed(2)
  let numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  })
  return numberFormat.format(price)
}

const ProductCard = ({ product }) => {
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)

    const price = new FormData(event.target).get('priceSelect')
    const stripe = await getStripe()
    const { error } = await stripe.redirectToCheckout({
      mode: 'payment',
      lineItems: [{ price, quantity: 1 }],
      successUrl: `${window.location.origin}/thankyou`,
      cancelUrl: `${window.location.origin}/en-US/subscribe`,
      billingAddressCollection: 'required',
    })

    if (error) {
      console.warn('Error:', error)
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="border-red-600 border-8 bg-gray-100 flex flex-col justify-center items-center m-5">
        <h2 className="font-serif font-semibold text-gray-600 text-center text-xl mt-4">
          <h4>{product.name}</h4>
        </h2>
        <img src={product.images[0]} alt="" className="w-full max-w-sm m-4" />
        <p className="text-lg text-gray-800 text-center mx-4">
          First issue will arrive in 2 - 4 weeks. Forbes Monaco is published 6
          times a year except for issues combined periodically into two and
          occasional extra, expanded, or premium issues.
        </p>
        <h3 id="price-release" className="text-5xl font-serif text-red-600 m-4">
          <select name="priceSelect">
            {product.prices.map((price) => (
              <option key={price.id} value={price.id}>
                {formatPrice(price.unit_amount, price.currency)}
              </option>
            ))}
          </select>
        </h3>
        <p className="mx-5 text-gray-500 text-sm border-t border-gray-400 text-center">
          *Shipping included for Monaco only. For other destinations please
          consult us:
        </p>
        <a
          className="text-sm text-center text-turmeric-500 hover:text-turmeric-400 mb-4"
          href="mailto:admin@forbes-monaco.com"
        >
          admin@forbes-monaco.com
        </a>
        <p className="text-lg text-red-600 font-serif text-center m-2">
          Learn More
        </p>
        <button
          disabled={loading}
          href="mailto:subscribe@forbesbenelux.com"
          className="text-white text-xl bg-red-600 px-10 py-3 font-serif mb-4 hover:text-white"
        >
          SUBSCRIBE
        </button>
      </div>
    </form>
  )
}

export default ProductCard

import React from 'react'
import * as PropTypes from 'prop-types'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import Products from '../components/Products/Products'
import Helmet from 'react-helmet'

const propTypes = {
  data: PropTypes.object.isRequired,
}

function Subscription({ location, data }) {
  return (
    <Layout data={data} location={location}>
      <Helmet>
        <title>Subscription - Forbes Netherlands</title>
      </Helmet>
      <div className="my-0 mx-4 relative">
        <div className="mb-32">
          <h1 className="text-center text-4xl font-bold text-black pt-32">
            CHOOSE YOUR SUBSCRIPTION
          </h1>
          <div className="flex flex-col md:flex-row">
            <Products />
          </div>
        </div>
      </div>
    </Layout>
  )
}

Subscription.propTypes = propTypes

export default Subscription

export const pageQuery = graphql`
  query SubscriptionENQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
